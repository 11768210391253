import moment from "moment";
import { API_BASE_URL } from "src/constants";
import {
  get,
  getUnauthorized,
  postUnauthorized,
  put,
} from "src/core/utils/api";
import { Order, OrderItem, OrderStatus } from "src/types/types";

const createOrderService = () => {
  const generateOrder = async (
    items: OrderItem[],
    menuIdentifier: string,
    orderCartId: string,
    customerNote?: string,
    tableId?: string
  ): Promise<Order> => {
    let total = 0;

    items.forEach((item) => {
      total = total + (item?.price ?? 0) * item.quantity;
    });

    const order: Order = {
      orderNumber: "",
      status: "",
      total: total,
      orderCartId: orderCartId,
      additionalDetails: customerNote,
      tableId: tableId,
    };

    const createdOrder = await createOrder(order, menuIdentifier);
    const response = await addOrderItems(createdOrder.orderNumber, items);

    return response;
  };

  const createOrder = async (
    order: Order,
    menuIdentifier: string
  ): Promise<Order> => {
    const response = await postUnauthorized<Order>(
      API_BASE_URL + `/menus/${menuIdentifier}/orders`,
      order
    );

    return response;
  };

  const addOrderItems = async (
    orderNumber: string,
    items: OrderItem[]
  ): Promise<Order> => {
    const response = await postUnauthorized<Order>(
      API_BASE_URL + `/orders/${orderNumber}/items`,
      {
        orderItems: items,
      }
    );

    return response;
  };

  const getUserOrders = async (): Promise<Order[]> => {
    const response = await get<Order[]>(API_BASE_URL + `/orders`);

    return response;
  };

  const getNewUserOrders = async (lastOrderId: number): Promise<Order[]> => {
    const response = await get<Order[]>(
      API_BASE_URL + `/orders?orderIdFrom=${lastOrderId}`
    );

    return response;
  };

  const getTodaysUserOrders = async (): Promise<Order[]> => {
    const response = await get<Order[]>(
      API_BASE_URL +
        `/orders?dateTimeFrom=${moment().format("yyyy-MM-DD 00:00:00")}`
    );

    return response;
  };

  const updateOrderStatus = async (
    id: number,
    status: OrderStatus
  ): Promise<Order> => {
    const response = await put<Order>(API_BASE_URL + `/orders/${id}`, {
      status: status,
    });

    return response;
  };

  const getPublicOrder = async (orderNumber: string): Promise<Order> => {
    const response = await getUnauthorized<Order>(
      API_BASE_URL + `/landing/orders/${orderNumber}`
    );

    return response;
  };

  const getPublicOrderItems = async (
    orderNumber: string
  ): Promise<OrderItem[]> => {
    const response = await getUnauthorized<OrderItem[]>(
      API_BASE_URL + `/landing/orders/${orderNumber}/items`
    );

    return response;
  };

  const getOrderItems = async (orderNumber: string): Promise<OrderItem[]> => {
    const response = await get<OrderItem[]>(
      API_BASE_URL + `/orders/${orderNumber}/items`
    );

    return response;
  };

  return {
    getUserOrders,
    updateOrderStatus,
    generateOrder,
    getPublicOrder,
    getPublicOrderItems,
    getOrderItems,
    getNewUserOrders,
    getTodaysUserOrders,
  };
};
export const orderService = createOrderService();
