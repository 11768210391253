import { MenuOutlined } from "@ant-design/icons";
import { Col, Image, Layout, Row } from "antd";
import React from "react";
import { Menu } from "src/types/types";

const { Header, Content } = Layout;

type Props = {
  menu?: Menu;
  showMenuNavigation: boolean;
  setShowMenuNavigation: (value: boolean) => void;
};

export const MenuHeader = (props: Props) => {
  const { menu, setShowMenuNavigation, showMenuNavigation } = props;

  return (
    <React.Fragment>
      {menu?.headerLogo && menu.headerLogo !== "" ? (
        <Header
          style={{
            minHeight: 120,
            textAlign: "center",
            lineHeight: "20px",
            paddingTop: "15px",
            paddingBottom: "25px",
            paddingRight: 20,
            paddingLeft: 20,
            verticalAlign: "middle",
          }}
        >
          <Row>
            <Col span={20} offset={2}>
              <Image
                className="header-image"
                width={200}
                style={{
                  width: "auto",
                  maxWidth: "200px",
                  maxHeight: 100,
                  margin: "0 auto",
                }}
                src={menu.headerLogo}
                preview={false}
                fallback={menu?.title}
              />
            </Col>
            {(menu?.showSidebar || false) && (
              <Col span={2}>
                <Row justify="center" align="middle" style={{ height: "90%" }}>
                  <Col span={24}>
                    <MenuOutlined
                      className="menu-sidebar-button"
                      style={{ fontSize: "2em" }}
                      onClick={() => {
                        setShowMenuNavigation(!showMenuNavigation);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Header>
      ) : (
        <Header
          style={{
            minHeight: 80,
            textAlign: "center",
            lineHeight: "20px",
            paddingTop: "15px",
            paddingBottom: "10px",
          }}
        >
          <Row>
            <Col span={20} offset={2}>
              {!menu?.description || menu?.description === "" ? (
                <Content>
                  <h1 style={{ marginTop: 15 }} className="menu-title">
                    {menu?.title}
                  </h1>
                </Content>
              ) : (
                <Content>
                  <h1 className="menu-title">{menu?.title}</h1>
                  <p className="menu-description">{menu?.description}</p>
                </Content>
              )}
            </Col>
            {(menu?.showSidebar || false) && (
              <Col span={2}>
                <Row justify="center" align="middle" style={{ height: "90%" }}>
                  <Col span={24} className="menu-sidebar-button">
                    <MenuOutlined
                      className="menu-sidebar-button"
                      style={{ fontSize: "2em" }}
                      onClick={() => {
                        setShowMenuNavigation(!showMenuNavigation);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Header>
      )}
    </React.Fragment>
  );
};
