import { Col, Layout, Row } from "antd";
import { useTranslation } from "react-i18next";

const { Footer, Content } = Layout;

export const PageFooter = () => {
  const { t } = useTranslation();

  return (
    <Footer style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Row justify={"center"}>
        <Col span={24}>
          <Content
            style={{ textAlign: "center", fontWeight: 600 }}
            className="menu-footer-text"
          >
            <a
              href={t("common.url")}
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noreferrer"
            >
              {t("common.urlLabel")}
            </a>{" "}
            | {t("common.year")}
          </Content>
        </Col>
      </Row>
    </Footer>
  );
};
